exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-o-mne-tsx": () => import("./../../../src/pages/o-mne.tsx" /* webpackChunkName: "component---src-pages-o-mne-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-recipe-recipe-detail-tsx": () => import("./../../../src/templates/recipe/recipe_detail.tsx" /* webpackChunkName: "component---src-templates-recipe-recipe-detail-tsx" */),
  "component---src-templates-recipe-recipe-tsx": () => import("./../../../src/templates/recipe/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-recipe-tsx" */),
  "component---src-templates-services-service-tsx": () => import("./../../../src/templates/services/service.tsx" /* webpackChunkName: "component---src-templates-services-service-tsx" */)
}

